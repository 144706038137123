import { Button } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

type Props = {
  value: string;
  onClick: () => void;
  startIcon?: ReactNode;
  className?: string;
} & typeof defaultProps;

const defaultProps = {
  startIcon: <></>,
  className: '',
}

export default function CustomButton({
  value, startIcon, onClick, className,
}: Props): ReactElement {
  return (
    <Button
      className={`${className || ''} custom-button`}
      variant="contained"
      onClick={onClick}
      startIcon={startIcon}
    >
      {value}
    </Button>
  )
}

CustomButton.defaultProps = defaultProps
