import { Column } from 'components/CustomTable/types'
import { ReactElement } from 'react'
import { GroupRequest } from 'services/cerbereTypes'
import { store } from 'Store'
import ActionButtons from './ActionButtons/ActionButtons'
import RefusalInput from './RefusalInput/RefusalInput'

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_COLUMNS: Column[] = [
  {
    propertyName: 'user',
    align: 'left',
    width: '130px',
    copy: false,
    formatter: (_: unknown, request: unknown) => (request as GroupRequest)?.user_display_name,
    show: () => true,
  },
  {
    propertyName: 'action',
    align: 'left',
    width: '130px',
    copy: false,
    formatter: (_: unknown, r: unknown): ReactElement => {
      const request = r as GroupRequest
      return <ActionButtons userId={request.user_id} username={request.user_display_name} />
    },
    show: () => true,
  },
  {
    propertyName: 'comment',
    align: 'left',
    width: '130px',
    copy: false,
    formatter: (_: unknown, request: unknown): ReactElement => (
      <RefusalInput
        userId={(request as GroupRequest)?.user_id}
      />
    ),
    show: () => store.getState().groups.requestApprovals.length !== 0,
  }]
