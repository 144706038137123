import { IconButton, ListItem } from '@mui/material'
import terms from 'common/terms'
import { HTMLAttributes, ReactElement, useState } from 'react'
import { User } from 'services/cerbereTypes'
import './AutocompleteOption.scss'
import AddIcon from '@mui/icons-material/Add'
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg'

type Props = {
  props: HTMLAttributes<HTMLLIElement>;
  user: User;
}
export default function AutocompleteOption({ props, user }: Props): ReactElement {
  const [isHovered, setHover] = useState(false)
  return (
    <ListItem
      className={`d-flex justify-content-between ${user.id === '0' ? 'option-import' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      {user.id !== '0' ? (
        <>
          <div className="d-flex w-100">
            <div className="option-name">
              {user.displayName}
            </div>
            <div className="option-email">
              {`${user.email}`}
            </div>
            <div className="option-email">
              {`${user.username}`}
            </div>
          </div>

          {isHovered && (
            <div className="option-add-icon">
              <IconButton disabled size="small">
                <AddIcon />
                <span>{terms.Common.add}</span>
              </IconButton>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center option-import-list">
          <IconButton disabled size="small">
            <ImportIcon />
            <span>{terms.Group.importUsernames}</span>
          </IconButton>
        </div>
      )}

    </ListItem>
  )
}
