import { Chip } from '@mui/material'
import terms from 'common/terms'
import { getCSSValue } from 'helpers'
import { ReactElement } from 'react'
import './RequestStatusChip.scss'
import { QueryStatus } from 'services/cerbereTypes'

type Props = {
  value: string;
}

export default function RequestStatusChip({ value }: Props): ReactElement {
  const getCustomStyle = () => {
    switch (value) {
      case QueryStatus.success:
        return getCSSValue('--color-success')
      case QueryStatus.failure:
        return getCSSValue('--color-failure')

      default:
        return getCSSValue('--color-pending')
    }
  }
  return (
    <Chip
      sx={{ backgroundColor: getCustomStyle() }}
      className="query-chip"
      label={terms.Common[value as keyof typeof terms.Common]}
    />
  )
}
