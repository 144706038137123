import { ListItem, ListItemButton } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedGroup } from 'reducers/group'
import { Group } from 'services/cerbereTypes'

type Props = {
  groups: Group;
  index: number;
  service: string;
}
export default function Item({ groups, index, service }: Props): ReactElement {
  const dispatch = useDispatch()

  return (
    <>
      <ListItem className="group-list-item" sx={{ pl: index * 4 }}>
        <ListItemButton onClick={() => dispatch(setSelectedGroup(groups))} className="group-text">
          <div className="group-name">
            {`${groups.display_name}`}
          </div>
          <div className="user-count">
            {`\u00A0(${groups.members_count}  ${+groups.members_count <= 1
              ? terms.Table.user.toLocaleLowerCase() : terms.Table.users.toLocaleLowerCase()})`}

          </div>

          {groups.requests_count > 0 && (
          <div className="requests">
            {`\u00A0${groups.requests_count} ${terms.Group.currentRequests(groups.requests_count)}`}
          </div>
          )}
        </ListItemButton>
      </ListItem>
      {
        groups?.children?.map(child => (
          <Item
            key={child.id}
            service={service}
            groups={child}
            index={index + 1}
          />
        ))
      }
    </>
  )
}
