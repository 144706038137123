import { InputBase, InputBaseComponentProps, InputBaseProps } from '@mui/material'
import './CustomInput.scss'
import { useState } from 'react'

type Props = {
 inputProps?: InputBaseComponentProps;
 baseProps?: InputBaseProps;
 label: string;
 value: string;
 onBlur: (val: string) => void;
 disabled?: boolean;
}

const defaultProps = {
  inputProps: undefined,
  disabled: false,
  baseProps: undefined,
}

export default function CustomInput({
  inputProps, label, value, baseProps, onBlur, disabled,
}: Props) {
  const [val, setVal] = useState(value)
  return (
    <div className="input-container">
      <div className="label">{label}</div>
      <InputBase
        {...baseProps}
        disabled={disabled}
        className="field"
        value={val}
        onChange={evt => setVal(evt.target.value)}
        inputProps={{ ...inputProps }}
        onBlur={evt => onBlur(evt.target.value)}
      />
    </div>
  )
}

CustomInput.defaultProps = defaultProps
