import {
  Button, Chip, Menu, MenuItem,
} from '@mui/material'
import terms from 'common/terms'
import { getCSSValue } from 'helpers'
import { MouseEvent, ReactElement, useState } from 'react'
import { Levels, SimpleUser } from 'services/cerbereTypes'
import './LevelChip.scss'

import { ExpandMore } from '@mui/icons-material'
import { isLevel1, isLevel2 } from 'helpers/permissions'
import { useDispatch } from 'react-redux'
import GroupServices from 'services/GroupServices'

type Props = {
  level: Levels;
  disabled: boolean;
  user: SimpleUser;
  groupId: string;
}
// todo generic ?
export default function LevelChip({
  level, disabled, user, groupId,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const color = getCSSValue(`--color-${level}`)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const removeAdmin = () => {
    if (isLevel1(user.id)) {
      dispatch(GroupServices.removeLevel({ groupId, level: Levels.level1, userId: user.id }))
    }
    if (isLevel2(user.id)) {
      dispatch(GroupServices.removeLevel({ groupId, level: Levels.level2, userId: user.id }))
    }
  }

  const changeLevel = () => {
    const newLevel = level === Levels.level1 ? Levels.level2 : Levels.level1
    if (newLevel === Levels.level1) {
      dispatch(GroupServices.removeLevel({ groupId, level: Levels.level2, userId: user.id }))
    } else {
      dispatch(GroupServices.assignToGroupWithLevel({ groupId, level: newLevel, user }))
    }
  }

  return (
    <>
      <Button
        className={`level-button ${anchorEl ? ' active' : ''}`}
        disabled={disabled}
        onClick={handleClick}
        endIcon={disabled ? <></> : <ExpandMore />}
      >
        <Chip
          className="chip"
          style={{ backgroundColor: color }}
          label={terms.Group[level]}
        />
      </Button>
      <Menu
        className="manage-users-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={removeAdmin}>{terms.Group.removeAdmin}</MenuItem>
        <MenuItem onClick={changeLevel}>
          {level === Levels.level1 ? terms.Group.assignLevel2
            : terms.Group.assignLevel1}
        </MenuItem>
      </Menu>
    </>
  )
}
