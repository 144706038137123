import { Button } from '@mui/material'
import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GroupState, updateApproval } from 'reducers/group'
import './ActionButtons.scss'
import { RequestStatus } from 'services/cerbereTypes'
import terms from 'common/terms'

type Props = {
  userId: string;
  username: string;
}

export default function ActionButtons({ userId, username }: Props): ReactElement {
  const dispatch = useDispatch()
  const { requestApprovals } = useSelector((state: RootState) => state.groups as GroupState)

  const handleRequest = (state: RequestStatus) => {
    const request = requestApprovals.find(elem => elem.userId === userId)
    dispatch(updateApproval({
      ...request, userId, state, user_display_name: username,
    }))
  }

  const getClassName = (status: string) => `${status} ${requestApprovals.find(elem => elem.userId === userId
     && elem.state === status) ? ' active' : ''}`

  return (
    <div className="action-buttons">
      <Button
        onClick={() => handleRequest(RequestStatus.approve)}
        className={getClassName('approve')}
        variant="outlined"
      >
        {terms.Group.approve}
      </Button>
      <Button
        onClick={() => handleRequest(RequestStatus.reject)}
        className={getClassName('reject')}
        variant="outlined"
      >
        {terms.Group.reject}
      </Button>
    </div>
  )
}
