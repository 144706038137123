import { LoadingButton } from '@mui/lab'
import { CircularProgress, Tooltip } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GroupState, resetEmailList } from 'reducers/group'
import GroupServices from 'services/GroupServices'
import './MailCopyButton.scss'

const PAGE_SIZE = 1000

export default function MailCopyButton(): ReactElement {
  const dispatch = useDispatch()
  const {
    selectedGroup, groupMembers, emailList,
    emailLoading,
  } = useSelector((state: RootState) => state.groups as GroupState)
  const [showTooltip, setTooltip] = useState(false)
  const page = useRef(1)

  const copyMails = () => {
    if (groupMembers.count) {
      dispatch(GroupServices.getGroupMembers({
        pageSize: PAGE_SIZE, page: 1, groupId: selectedGroup.id, fetchingMail: true,
      }))
    }
  }

  useEffect(() => {
    if (!emailList.count) return

    if (emailList.next) {
      page.current += 1
      dispatch(GroupServices.getGroupMembers({
        pageSize: PAGE_SIZE,
        page: page.current,
        groupId: selectedGroup.id,
        fetchingMail: true,
      }))
    } else {
      page.current = 1
      const list = emailList.results.flatMap(user => user.email || [])
      navigator.clipboard.writeText(list.join(';')).then(() => {
        setTooltip(true)
      })
      dispatch(resetEmailList())
    }
  }, [emailList])

  const onToolTipClose = () => {
    setTooltip(false)
  }

  return (
    <Tooltip arrow title={terms.Common.copied} open={showTooltip} onClose={onToolTipClose}>
      <LoadingButton
        className="mail-button"
        disabled={emailLoading || !selectedGroup.members_count}
        loading={emailLoading}
        onClick={copyMails}
        loadingPosition="end"
        loadingIndicator={<CircularProgress color="inherit" size={12} />}
        endIcon={<></>}
      >
        {terms.Group.copyMail}
      </LoadingButton>
    </Tooltip>
  )
}
