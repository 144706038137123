/* eslint-disable no-restricted-syntax */
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import {
  Checkbox,
  Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material'
import terms from 'common/terms'
import InfoPanel, { Severity } from 'components/InfoPanel/InfoPanel'
import {
  ChangeEvent, Fragment, ReactElement, useEffect, useRef, useState,
} from 'react'
import { Group, Service } from 'services/cerbereTypes'
import Item from './Item'

type Props = {
  groups: Service[];
  mode?: string;
  setLength: (length: number) => void;
  setGroups: (groupIds: string[]) => void;
} & typeof defaultProps

const defaultProps = {
  mode: 'user',
}

type EmptyObject = {
  [k: string]: string[];
}

export default function GroupList({
  mode, groups, setGroups, setLength,
}: Props): ReactElement {
  const [open, setOpen] = useState<number[]>([])
  const [checked, setChecked] = useState<string[]>([])
  const [groupList, setGroupList] = useState<EmptyObject>({})
  const groupChildren = useRef<EmptyObject>({})

  const findChildren = (currentObj: Group, val: string[] = [currentObj.id]) => {
    if (currentObj.children) {
      currentObj.children?.forEach(child => {
        if (child.can_managed) {
          val.push(child.id)
        }
        findChildren(child, val)
      })
    }
    return val
  }

  const initUserGroup = () => {
    const tmpGroup: EmptyObject = {}
    const children: EmptyObject = {}
    const iterate = (val: Group, name: string, child: EmptyObject) => {
      val?.children?.forEach(elem => {
        child[elem.id] = findChildren(elem)
        tmpGroup[name].push(elem.id)
        iterate(elem, name, child)
      })
    }
    groups?.forEach(service => {
      tmpGroup[service.project_name] = []
      service.groups.forEach(group => {
        tmpGroup[service.project_name].push(group.id)
        children[group.id] = findChildren(group)
        iterate(group, service.project_name, children)
      })
    })
    groupChildren.current = children
    let length = 0
    Object.values(tmpGroup).forEach(elem => {
      length += elem.length
    })
    setLength(length)
    setGroupList(tmpGroup)
  }

  useEffect(() => {
    initUserGroup()
  }, [])

  const handleToggle = (index: number) => {
    const currentIndex = open.indexOf(index)
    const newOpen = [...open]
    if (currentIndex === -1) {
      newOpen.push(index)
    } else {
      newOpen.splice(currentIndex, 1)
    }
    setOpen(newOpen)
  }
  const checkService = (service: string) => {
    if (groupList[service]?.length) {
      return groupList[service].every(e => checked.includes(e))
    }
    return false
  }

  const isChecked = (id: string): boolean => checked.includes(id)

  const handleCheck = (_event: ChangeEvent<HTMLInputElement>, values: string[], service: string) => {
    let newChecked = []
    if (!values.length) {
      if (!checkService(service)) {
        newChecked = [...new Set([...checked, ...groupList[service]])]
      } else {
        const toRemove = groupList[service]
        newChecked = checked.filter(e => !toRemove.includes(e))
      }
    } else {
      const currentIndex = checked.indexOf(values[values.length - 1])
      if (currentIndex === -1) {
        const toAdd = values
        newChecked = [...new Set([...checked, ...toAdd])]
      } else {
        newChecked = checked.filter(e => e !== values[values.length - 1])
      }
    }

    setChecked(newChecked)
    setGroups(newChecked)
  }

  return (
    <>
      {groups?.length === 0 ? (
        <InfoPanel
          title={terms.Group.notFound}
          severity={Severity.warning}
        />
      )
        : (
          <List>
            {groups?.map((service, index) => (
              <Fragment key={service.project_name}>
                <ListItem
                  style={{ width: 'fit-content' }}
                  disablePadding
                >
                  {mode === 'user'
                    && (
                      <ListItemIcon className="checkbox title">
                        <Checkbox
                          onChange={evt => handleCheck(evt, [], service.project_name)}
                          checked={checkService(service.project_name)}
                          checkedIcon={<IndeterminateCheckBoxIcon />}
                        />
                      </ListItemIcon>
                    )}

                  <ListItemText className="list-title-text" primary={service.project_name} />
                  <IconButton className="expand-icon" onClick={() => handleToggle(index)}>
                    {open.indexOf(index) !== -1 ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItem>
                <Collapse in={open.indexOf(index) !== -1} timeout="auto" unmountOnExit>
                  {service.groups.map(group => (
                    <Item
                      checked={isChecked}
                      service={service.project_name}
                      handleCheck={handleCheck}
                      key={group.id}
                      groups={group}
                      index={1}
                    />
                  ))}
                </Collapse>
              </Fragment>
            ))}

          </List>
        )}
    </>
  )
}

GroupList.defaultProps = defaultProps
