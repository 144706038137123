import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import CustomTable from 'components/CustomTable/CustomTable'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import permissionColumns from 'components/TokenInfo/const'
import { ReactNode } from 'react'
import { Permission } from 'services/cerbereTypes'
import './PermissionsModal.scss'
import terms from 'common/terms'

type Props = {
  open: boolean;
  toggle: () => void;
  permissions: Permission[];
  slug: string;
  warning: string;
  title: ReactNode;
}
export default function PermissionsModal({
  open, toggle, slug, permissions, warning, title,
}: Props) {
  return (
    <ModalWrapper
      className="permissions-modal"
      toggle={toggle}
      open={open}
      title={title}
      footerButton={false}
    >
      {warning && (
      <div className="sync-error">
        <WarningIcon width="20" height="20" fill="#E83F3F" />
        <div className="text">
          {warning}
        </div>
      </div>
      )}

      <div className="slug">
        {terms.Group.slug}
        {slug}
      </div>

      <CustomTable
        values={permissions}
        loader={false}
        count={permissions.length}
        pagination={false}
        columns={permissionColumns}
        changePage={() => undefined}
      />

    </ModalWrapper>
  )
}
