/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AssignPayload,
  GroupDetails,
  GroupPayload,
  GroupUpdate,
  MembershipRequest,
  RemoveResponse,
  RequestState,
  ServiceResponse, TokenInfo, User, UserAssignResponse, UserResponse,
} from 'services/cerbereTypes'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const addUsersToGroup = createAsyncThunk<RemoveResponse, {groupId: string, users: User[] | string[], withCp: boolean},
 ThunkApiConfig>(
   'groups/addUsersToGroup',
   async ({ groupId, users, withCp }, thunkApi) => {
     try {
       const ids = withCp ? users : users.map(u => (u as User).id)
       const response = await post(`/${URI.cerbereApi}/v2/groups/${groupId}/members`, ids)
       return response
     } catch (e: any) {
       return thunkApi.rejectWithValue({
         data: e.response.data,
         code: e.response.status,
       })
     }
   },
 )
const removeUsersFromGroup = createAsyncThunk<RemoveResponse, {groupId: string, userIds: string[]}, ThunkApiConfig>(
  'groups/removeUsersFromGroup',
  async ({ groupId, userIds }, thunkApi) => {
    try {
      const response = await deleteRequest(`/${URI.cerbereApi}/v2/groups/${groupId}/members/`, { data: userIds })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroups = createAsyncThunk<ServiceResponse, string, ThunkApiConfig>(
  'groups/getGroups',
  async (search, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/groups/`, { search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroupPermissions = createAsyncThunk<TokenInfo, string, ThunkApiConfig>(
  'groups/getGroupPermissions',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/groups/${id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroupDetails = createAsyncThunk<GroupDetails, string, ThunkApiConfig>(
  'groups/getGroupDetails',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/groups/${id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroupMembers = createAsyncThunk<UserResponse, GroupPayload, ThunkApiConfig>(
  'groups/getGroupMembers',
  async ({
    page, pageSize, groupId, search,
  }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/groups/${groupId}/members/`, {
        // eslint-disable-next-line camelcase
        page_size: pageSize || undefined,
        page,
        search: search || undefined,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const assignToGroupWithLevel = createAsyncThunk<UserResponse, AssignPayload, ThunkApiConfig>(
  'groups/assignToGroupWithLevel',
  async ({ groupId, level, user }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/v2/groups/${groupId}/${level}/${user.id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const modifyInfo = createAsyncThunk<GroupDetails, GroupUpdate, ThunkApiConfig>(
  'groups/modifyInfo',
  async (params, thunkApi) => {
    try {
      const { id, ...rest } = params
      const response = await patch(`/${URI.cerbereApi}/v2/groups/${id}/`, rest)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const assignAdmin = createAsyncThunk<any, any, ThunkApiConfig>(
  'groups/assignAdmin',
  async ({ groupId, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/assign_admin/${userId}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const removeAdmin = createAsyncThunk<any, {groupId: string, userId: string}, ThunkApiConfig>(
  'groups/removeAdmin',
  async ({ groupId, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/remove_admin/${userId}`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const assignUsers = createAsyncThunk<UserAssignResponse, any, ThunkApiConfig>(
  'groups/assignUsers',
  async ({ groupId, cp }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/groups/${groupId}/assign_users/`, { cp })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const removeLevel = createAsyncThunk<void, {level:string, groupId: string, userId: string}, ThunkApiConfig>(
  'groups/removeLevel',
  async ({ groupId, level, userId }, thunkApi) => {
    try {
      const response = await deleteRequest(`/${URI.cerbereApi}/v2/groups/${groupId}/${level}/${userId}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getMembershipRequests = createAsyncThunk<MembershipRequest, void, ThunkApiConfig>(
  'groups/getRequests',
  async (_, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/users/requests`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const approveRejectRequest = createAsyncThunk<void, RequestState & {groupId: string}, ThunkApiConfig>(
  'groups/approveRejectRequest',
  async (request, thunkApi) => {
    try {
      // eslint-disable-next-line max-len
      const response = await post(`/${URI.cerbereApi}/v2/users/requests/${request.groupId}/${request.userId}/${request.state}`,
        { comment: request.reason })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const GroupServices = {
  addUsersToGroup,
  removeUsersFromGroup,
  getGroups,
  getGroupMembers,
  getGroupPermissions,
  assignAdmin,
  removeAdmin,
  getGroupDetails,
  modifyInfo,
  assignUsers,
  assignToGroupWithLevel,
  removeLevel,
  getMembershipRequests,
  approveRejectRequest,
}

export default GroupServices
