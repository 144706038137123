import { InputBase } from '@mui/material'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import terms from 'common/terms'
import CustomTable from 'components/CustomTable/CustomTable'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { DateTime } from 'luxon'
import { ReactElement, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetToken, TokenState } from 'reducers/token'
import TokenServices from 'services/TokenServices'
import { AppDispatch, RootState, store } from 'Store'
import './AskTokenPopup.scss'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import columnsHeader from './const'

type Props = {
  open: boolean;
  toggle: () => void;
}
const debouncedSearch = debounce(
  (val: string) => store.dispatch(TokenServices.getPermissions(val)),
  200,
)
export default function AskTokenPopup({ open, toggle }: Props): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const { permissions, askPermissions } = useSelector((state: RootState) => state.tokens as TokenState)
  const expiryDate = DateTime.fromJSDate(new Date()).plus({ months: 3 })
  const [tokenName, setTokenName] = useState('')
  const [step, setStep] = useState(1)
  const [reason, setReason] = useState('')

  const resetForm = () => {
    setStep(1)
    dispatch(resetToken())
    setTokenName('')
    setReason('')
  }

  useEffect(() => {
    dispatch(TokenServices.getPermissions(''))
  }, [])

  useEffect(() => {
    resetForm()
  }, [open])

  const askToken = async () => {
    await dispatch(TokenServices.createRequest({
      slug: tokenName,
      permissions: askPermissions,
      reason,
      expires_on: expiryDate.toISODate() as string,
    }))
    toggle()
  }

  const getConfirmButton = () => {
    switch (step) {
      case 1:
        return (
          <Button
            disabled={tokenName.trim() === ''}
            className="button"
            variant="contained"
            onClick={() => setStep(2)}
          >
            {terms.Common.continue}
          </Button>
        )

      case 2:
        return (
          <Button
            disabled={reason.trim() === ''}
            className="button"
            variant="contained"
            onClick={() => setStep(3)}
          >
            {terms.Common.continue}
          </Button>
        )

      case 3:
        return (
          <Button
            disabled={!askPermissions.length}
            className="button"
            variant="contained"
            onClick={() => setStep(4)}
          >
            {terms.Token.askMultiple(askPermissions.length)}
          </Button>
        )

      case 4:
        return (
          <div className="actions">
            <Button className="button cancel" variant="contained" onClick={toggle}>{terms.Common.cancel}</Button>
            <Button className="button" variant="contained" onClick={askToken}>{terms.Common.confirm}</Button>
          </div>
        )

      default:
        return <></>
    }
  }

  const getTitle = () => {
    switch (step) {
      case 1:
        return terms.Token.ask

      case 2:
        return terms.Token.askingReason

      case 3:
        return terms.Token.selectPermissions

      case 4:
        return terms.Common.confirmation

      default:
        return terms.Token.ask
    }
  }
  const renderApp = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="container">
              <div className="label">{terms.Token.name}</div>
              <InputBase
                className="field"
                onChange={evt => {
                  setTokenName(evt.target.value)
                }}
              />
            </div>

            <div className="d-flex expiry w-100">
              <WarningIcon width="20" height="20" fill="#546EFE" />
              <div className="date-limit">
                {`${terms.Token.willExpire} ${expiryDate.toFormat('dd/MM/yyyy')}`}
              </div>
            </div>

          </>
        )

      case 2:
        return (
          <div className="container">
            <div className="label">{terms.Token.reason}</div>
            <InputBase
              className="field"
              value={reason}
              onChange={evt => {
                setReason(evt.target.value)
              }}
            />
          </div>
        )
      case 3:
        return (
          <>

            <SearchInput value="" onChange={e => debouncedSearch(e)} />
            <CustomTable
              values={permissions}
              loader={false}
              count={permissions.length}
              pagination={false}
              columns={columnsHeader}
              changePage={() => undefined}
            />
          </>

        )

      case 4:
        return (
          <div className="confirmation">

            <div className="reason">

              <div className="title">{`${terms.Token.askingReason} :`}</div>

              {`\n${reason}`}

            </div>

            <div className="title">
              {`\n${terms.Token.askedPermissions} :

              ${tokenName}
              
              `}
            </div>

            <div className="permissions">
              {askPermissions
                .map(perm => <span key={perm}>{perm}</span>)}
            </div>
          </div>
        )
      default:
        return <></>
    }
  }
  return (
    <ModalWrapper
      className="ask-token"
      toggle={toggle}
      open={open}
      title={getTitle()}
      footerButton={false}
      footer={getConfirmButton()}
    >
      {renderApp()}
    </ModalWrapper>
  )
}
