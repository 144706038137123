/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  Token, TokenInfo, TokenPermission, TokenRequest,
} from 'services/cerbereTypes'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const getTokens = createAsyncThunk<Token[], any, ThunkApiConfig>(
  'groups/getTokens',
  async ({ search }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/tokens/`, { search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTokenInfo = createAsyncThunk<TokenInfo, any, ThunkApiConfig>(
  'groups/getTokenInfo',
  async ({ id }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/tokens/${id}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPermissions = createAsyncThunk<TokenPermission[], string, ThunkApiConfig>(
  'tokens/getPermissions',
  async (search, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/permissions/`, { search })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const createRequest = createAsyncThunk<{id: number}, TokenRequest, ThunkApiConfig>(
  'tokens/createRequest',
  async (request, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/v2/tokens/requests/`, { ...request })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TokenServices = {
  getTokens, getTokenInfo, getPermissions, createRequest,
}

export default TokenServices
