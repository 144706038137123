import { ReactElement } from 'react'
import { GroupDetails, Levels } from 'services/cerbereTypes'
import LevelChip from './LevelChip'
import './UserLevelSelect.scss'

type Props = {
  groupInfo: GroupDetails;
  disabled: boolean;
  groupId: string;
}

export default function UserLevelSelect({ groupInfo, disabled, groupId }: Props): ReactElement {
  // dedupe users and add their role
  const users = groupInfo.level2_users
    .concat(groupInfo.level1_users
      .flatMap(user => (groupInfo.level2_users
        .some(u => u.id === user.id) ? [] : user)))
    .map(user => ({
      id: user.id,
      display_name: user.display_name,
      level: groupInfo.level2_users.some(us => us.id === user.id) ? Levels.level2 : Levels.level1,
    }))

  return (
    <div className="level-select">
      {users.map(user => (
        <div key={user.id} className="user">
          <div className="name">{user.display_name}</div>
          <div><LevelChip groupId={groupId} user={user} disabled={disabled} level={user.level} /></div>
        </div>
      ))}
    </div>
  )
}
