import terms from 'common/terms'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setGroupSearch } from 'reducers/group'
import { RootState, store } from 'Store'
import './GroupBar.scss'

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setGroupSearch(val)),
  200,
)
type Props = {
  length: number;
}
export default function GroupBar({ length }: Props): ReactElement {
  const dispatch = useDispatch()
  const { groupsLoading, groupSearch } = useSelector((state: RootState) => state.groups)

  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setGroupSearch(value))
      return
    }
    debouncedSearch(value)
  }

  return (
    <div className="group-bar">
      <div className="group-count">
        {!groupsLoading && (
          <span>
            {`${length} ${(length > 1 ? terms.Group.apps : terms.Group.app).toLowerCase()}`}
          </span>
        )}

      </div>

      <div className="group-buttons-container">
        <SearchInput value={groupSearch} onChange={newValue => handleChange(newValue)} />
      </div>
    </div>
  )
}
