import { Chip } from '@mui/material'
import { ReactElement } from 'react'
import { User } from 'services/cerbereTypes'
import './UserChip.scss'

type Props = {
  user: User;
  onDelete: (user: User) => void
}

export default function UserChip({ user, onDelete }: Props): ReactElement {
  return (
    <Chip
      className="user-chip"
      label={user.displayName}
      onDelete={() => {
        onDelete(user)
      }}
    />
  )
}
