import { isAnyOf } from '@reduxjs/toolkit'
import GroupServices from 'services/GroupServices'
import UserServices from 'services/UserServices'

export const updateErrorMatcher = isAnyOf(
  UserServices.enableUser.rejected,
  UserServices.disableUser.rejected,
  GroupServices.modifyInfo.rejected,
)

export const updateFulfilledMatcher = isAnyOf(
  UserServices.enableUser.fulfilled,
  UserServices.disableUser.fulfilled,
)

export const updatePendingMatcher = isAnyOf(
  UserServices.enableUser.pending,
  UserServices.disableUser.pending,
)
