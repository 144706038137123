import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GridOrder } from 'objects/types'
import {
  AssignResponse, User, UserCreationResponse, UserResponse,
} from 'services/cerbereTypes'
import UserServices from '../services/UserServices'

export interface UserState {
  users: UserResponse,
  usersLoading: boolean;
  userSearch: string;
  showUserGroup: string;
  userInfo: Partial<User>;
  userAssign: AssignResponse;
  userAssignLoading: boolean;
  userGroupSearch: string | null;
  showModal: boolean,
  createUserLoading: boolean;
  createdUsers: UserCreationResponse;
  sort: GridOrder
}

const initialCreation = {
  users_creation_fail: [], users_creation_success: [],
}
const initialState: UserState = {
  users: {
    results: [], count: 0, page_size: 20, next: null, previous: null,
  },
  usersLoading: true,
  userSearch: '',
  showUserGroup: '',
  userInfo: {},
  userAssign: { groups_assign_fail: [], groups_assign_success: [] },
  userAssignLoading: false,
  userGroupSearch: null,
  showModal: false,
  createUserLoading: false,
  createdUsers: initialCreation,
  sort: {
    field: '',
    sort: undefined,
  },
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.userSearch = action.payload
    },
    setCreateModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    setUserGroup: (state, action: PayloadAction<string>) => {
      state.showUserGroup = action.payload
    },
    setUserInfo: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload
    },
    setUserGroupSearch: (state, action: PayloadAction<string | null>) => {
      state.userGroupSearch = action.payload
    },
    setSort: (state, action: PayloadAction<GridOrder>) => {
      state.sort = action.payload
    },
    resetUserSearch: state => {
      state.userSearch = ''
      state.userGroupSearch = ''
      state.showUserGroup = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(UserServices.getUsers.fulfilled, (state, action) => {
      state.users = action.payload
      state.usersLoading = false
    })
    builder.addCase(UserServices.getUsers.pending, state => {
      state.usersLoading = true
    })
    builder.addCase(UserServices.getUser.fulfilled, (state, action) => {
      state.userInfo = action.payload
      state.usersLoading = false
    })
    builder.addCase(UserServices.getUser.pending, state => {
      state.usersLoading = true
    })
    builder.addCase(UserServices.enableUser.fulfilled, (state, action) => {
      state.users.results = state.users.results.map(user => (user.id === action.payload.id
        ? { ...user, enabled: true } : user))
    })
    builder.addCase(UserServices.disableUser.fulfilled, (state, action) => {
      state.users.results = state.users.results.map(user => (user.id === action.payload.id
        ? { ...user, enabled: false } : user))
    })
    builder.addCase(UserServices.assignToGroups.fulfilled, (state, action) => {
      state.userAssign = action.payload
      state.userAssignLoading = false
    })
    builder.addCase(UserServices.assignToGroups.pending, state => {
      state.userAssignLoading = true
    })
    builder.addCase(UserServices.assignToGroups.rejected, (state, action) => {
      if (action.payload?.data.groups_assign_fail) {
        state.userAssign = action.payload.data as AssignResponse
      }
      state.userAssignLoading = false
    })
    builder.addCase(UserServices.removeFromGroups.fulfilled, (state, action) => {
      state.userAssign = action.payload
      state.userAssignLoading = false
    })
    builder.addCase(UserServices.removeFromGroups.pending, state => {
      state.userAssignLoading = true
    })
    builder.addCase(UserServices.removeFromGroups.rejected, (state, action) => {
      if (action.payload?.data.groups_assign_fail) {
        state.userAssign = action.payload.data as AssignResponse
      }
      state.userAssignLoading = false
    })
    builder.addCase(UserServices.createUser.pending, state => {
      state.createdUsers = initialCreation
      state.createUserLoading = true
    })
    builder.addCase(UserServices.createUser.fulfilled, (state, action) => {
      state.createdUsers.users_creation_success = action.payload as string[]
      state.createdUsers.users_creation_fail = []
      state.createUserLoading = false
    })
    builder.addCase(UserServices.createUser.rejected, (state, action) => {
      if (action.payload?.code === 400) {
        state.createdUsers = action.payload.data
      }
      state.createUserLoading = false
    })
  },
})

export const {
  setSearch,
  setUserGroup,
  setUserInfo,
  setUserGroupSearch,
  setCreateModal,
  setSort,
  resetUserSearch,
} = usersSlice.actions

export default usersSlice.reducer
