import { Button } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GroupState } from 'reducers/group'
import GroupServices from 'services/GroupServices'

type Props = {
  toggle: () => void
}
export default function Buttons({ toggle }: Props): ReactElement {
  const dispatch = useDispatch()
  const { requestApprovals, selectedGroup } = useSelector((state: RootState) => state.groups as GroupState)

  const sendAction = () => {
    requestApprovals.forEach(request => {
      dispatch(GroupServices.approveRejectRequest({ ...request, groupId: selectedGroup.id }))
    })
  }

  return (
    <div className="custom-footer">
      <Button
        className="cancel-btn"
        variant="contained"
        onClick={toggle}
      >
        {terms.Common.cancel}
      </Button>
      <Button
        className="confirm-btn"
        variant="contained"
        // todo send query
        onClick={sendAction}
      >
        {terms.Common.confirm}
      </Button>
    </div>
  )
}
