import { store } from 'Store'
import { GroupState } from 'reducers/group'

const ROOT_PERMISSIONS = 'cerbere::ROOT'
const EDITOR_PERMISSIONS = 'cerbere::EDITOR'
const CAN_ADD_OUTSIDE_USER_PERMISSIONS = 'cerbere::CAN_ADD_OUTSIDE_USER'
const ACCESS_PERMISSIONS = 'cerbere::ACCESS'
const MANAGER_PERMISSIONS = ['cerbere::LEVEL_1', 'cerbere::LEVEL_2']

export const isRoot = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(ROOT_PERMISSIONS)
}

export const isEditor = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(EDITOR_PERMISSIONS)
}

export const canAddOutsideUser = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(CAN_ADD_OUTSIDE_USER_PERMISSIONS)
}

export const isUser = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(ACCESS_PERMISSIONS)
}

export const isManager = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.some((perm: string) => MANAGER_PERMISSIONS.includes(perm))
}

// todo with group param ?
export const isLevel1 = (userId: string): boolean => {
  const { details } = store.getState().groups as GroupState
  return details.level1_users.some(user => user.id === userId)
}

export const isLevel2 = (userId: string): boolean => {
  const { details } = store.getState().groups as GroupState
  return details.level2_users.some(user => user.id === userId)
}
