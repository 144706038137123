/* eslint-disable camelcase */
import {
  Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material'
import terms from 'common/terms'
import InfoPanel, { Severity } from 'components/InfoPanel/InfoPanel'
import Loader from 'components/Loader'
import { ReactElement, ReactNode } from 'react'
import { UnknownObject } from 'types'
import CustomTableHead from './CustomTableHead'
import { Column } from './types'

type Props = {
  values: UnknownObject[];
  loader: boolean;
  count: number;
  page_size?: number;
  columns: Column[];
  page?: number;
  pagination?: boolean;
  changePage: (newPage: number) => void
  customRows?: ReactNode;
} & typeof defaultProps

const defaultProps = {
  page_size: 20,
  pagination: true,
  page: 1,
  customRows: <></>,
}
export default function CustomTable({
  values, loader, count, page_size, columns, page, pagination, changePage, customRows,
}: Props): ReactElement {
  const totalPages = Math.ceil(count / page_size) || 1

  const computeId = (obj: UnknownObject) => {
    if ('id' in obj) {
      return obj.id
    }
    if ('token_id' in obj) {
      return obj.token_id
    }
    return obj.role_name
  }

  const renderData = () => {
    if (loader) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Loader />
          </TableCell>
        </TableRow>
      )
    }
    if (values.length > 0) {
      return (values.map(val => (
        <TableRow
          hover
          key={crypto.randomUUID()}
        >
          {columns.map(column => (
            column.show() && (
              <TableCell
                key={`${computeId(val)}-${column.propertyName}`}
                align={column.align}
                sx={{
                  minWidth: column.width,
                  maxWidth: column.width,
                  paddingLeft: column.copy ? '0' : '16',
                }}
              >
                {column.formatter(val[column.propertyName], val)}
              </TableCell>
            )

          ))}
        </TableRow>
      )))
    }
    return (
      <TableRow>
        <TableCell colSpan={columns.length}>
          <InfoPanel
            title={terms.Table.noResults}
            severity={Severity.warning}
          />
        </TableCell>

      </TableRow>
    )
  }
  return (
    <Paper className="table-container" elevation={0} square>

      <TableContainer>
        <Table style={{ height: loader ? '100%' : 'auto' }} stickyHeader aria-label="simple table">
          <CustomTableHead
            cols={columns}
          />

          <TableBody style={{ height: '100%' }}>
            {!loader && customRows}
            {renderData()}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination
        && (
          <div className="d-flex align-items-center justify-content-center pagination">
            <Pagination
              onChange={(_evt, newPage) => changePage(newPage)}
              variant="outlined"
              page={page || undefined}
              shape="rounded"
              boundaryCount={1}
              siblingCount={1}
              count={totalPages}
            />
          </div>
        )}
    </Paper>
  )
}

CustomTable.defaultProps = defaultProps
