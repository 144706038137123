import {
  Chip, FormControlLabel, Radio, RadioGroup,
} from '@mui/material'
import terms from 'common/terms'
import { getCSSValue } from 'helpers'
import { ReactElement } from 'react'
import { Levels } from 'services/cerbereTypes'
import './AdminMode.scss'

type Props = {
  level: Levels;
  onChange: (val: Levels) => void
}

export default function AdminMode({ level, onChange }: Props): ReactElement {
  const level1 = getCSSValue('--color-level1')
  const level2 = getCSSValue('--color-level2')
  return (
    <div className="mode-wrapper">
      <span>{terms.Group.chooseLevel}</span>
      <RadioGroup
        onChange={evt => onChange(evt.target.value as Levels)}
      >
        <FormControlLabel
          checked={level === Levels.level1}
          value={Levels.level1}
          control={<Radio />}
          label={(
            <Chip
              className="chip"
              style={{ backgroundColor: level1 }}
              label={terms.Group.level1}
            />
)}
        />
        <FormControlLabel
          checked={level === Levels.level2}
          value={Levels.level2}
          control={<Radio />}
          label={(
            <Chip
              className="chip"
              style={{ backgroundColor: level2 }}
              label={terms.Group.level2}
            />
)}
        />
      </RadioGroup>
    </div>
  )
}
