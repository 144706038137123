import { SentryConfigured } from '@osrdata/app_core'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'variables.scss'
import App from './App'
import { persistor, store } from './Store'
import './index.scss'
import * as serviceWorker from './serviceWorker'

import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'

SentryConfigured()
initTranslation()

Sentry.init({
  dsn: 'https://58391ec9270e4c468ce7edf36b1aed13@sentry.shared.dgexsol.fr/47',
  environment: process.env.REACT_APP_REALM,
  integrations: [new BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 0.01,
})

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
