import { auth } from '@osrdata/app_core'
import {
  ReactElement, StrictMode, Suspense, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect, Route, Router, Switch,
} from 'react-router-dom'

import AccessDenied from 'AccessDenied'
import 'App.scss'
import {
  EDITOR_HOMEPATH, EDITOR_ROUTES, RouteType, USER_HOMEPATH, USER_ROUTES,
} from 'Routes'
import { RootState } from 'Store'
import terms from 'common/terms'
import Loader from 'components/Loader'
import { NavElement } from 'components/TabList/TabElement'
import TabList from 'components/TabList/TabList'
import { editorElements, userElements } from 'components/TabList/const'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import { isManager, isUser } from 'helpers/permissions'

const APP_NAME = 'Cerbère'

const ACCESS_PERM = 'cerbere::ACCESS'
export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)
  const [homePath, setHomePath] = useState<string | undefined>(undefined)
  const [routes, setRoutes] = useState<Array<RouteType> | undefined>(undefined)
  const [accessDenied, setAccessDenied] = useState(false)
  const [tabs, setTabs] = useState<NavElement[]>([])

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  const getUserParams = (): void => {
    switch (true) {
      case isManager():
        setHomePath(EDITOR_HOMEPATH)
        setRoutes(EDITOR_ROUTES)
        setTabs(editorElements)
        break
      case isUser():
        setHomePath(USER_HOMEPATH)
        setRoutes(USER_ROUTES)
        setTabs(userElements)
        break
      default:
        setRoutes([])
        setHomePath('')
        break
    }
  }

  useEffect(() => {
    if (isLogged && !isLoading) {
      getUserParams()
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions, isLoading])

  const displayApp = !isLoading && isLogged && (routes !== undefined) && (homePath !== undefined)

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar appName={APP_NAME} innerComponent={<TabList tabs={tabs} />} />
          {accessDenied ? <AccessDenied /> : (
            <div className="app-container">
              {!displayApp && <Loader message={terms.Common.loading} />}
              {displayApp && (
                <Switch>
                  {routes.map(route => (
                    <Route key={route.path} component={route.component} path={route.path} />
                  ))}
                  <Redirect to={homePath} />
                </Switch>
              )}
            </div>
          )}
        </Router>
      </Suspense>
    </StrictMode>
  )
}
