import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Modal, Paper } from '@mui/material'

import { RootState } from 'Store'
import terms from 'common/terms'
import {
  ReactElement, useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserState, setCreateModal } from 'reducers/user'
import UserServices from 'services/UserServices'
import AlternativeUserCreation from './AlternativeUserCreation'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import './UserCreation.scss'

export const usernameRule = /\s+/

export default function UserCreation(): ReactElement {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [usernames, setUsernames] = useState('')
  const formattedUsernames = usernames.trim().split(usernameRule)
  const { showModal, createdUsers, createUserLoading } = useSelector((state: RootState) => state.users as UserState)

  const createUser = async () => {
    await dispatch(UserServices.createUser({ cp: formattedUsernames }))
  }

  const nextStep = (next: number) => {
    if (next === 2 && step === 1) {
      createUser()
    } else {
      setStep(next)
    }
  }

  const handleClose = () => {
    setStep(1)
    setUsernames('')
    dispatch(setCreateModal(false))
  }
  useEffect(() => {
    if (!createUserLoading
       && (createdUsers.users_creation_fail?.length || createdUsers.users_creation_success?.length)) {
      setStep(2)
    }
  }, [createdUsers])

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      open={showModal}
      onClose={handleClose}
    >
      <Paper
        className="d-flex row align-items-between justify-content-center user-creation-modal"
        elevation={0}
        square
      >
        {(step !== 2)
          && (
            <div className="d-flex row w-100 align-items-start header-modal justify-content-between">
              <div className="title">{terms.User.create}</div>

              <IconButton onClick={handleClose} className="close-button">
                <CloseIcon />
              </IconButton>

            </div>
          )}

        {(step === 1)
          && (
            <FirstStep
              onClick={nextStep}
              usernames={usernames}
              formatted={formattedUsernames}
              onChange={setUsernames}
            />
          )}

        {step === 2 && !createUserLoading
          && (
            <SecondStep
              handleClick={handleClose}
            />
          )}

        {step === 4 && (
          <AlternativeUserCreation goTo={nextStep} />
        )}
      </Paper>
    </Modal>
  )
}
