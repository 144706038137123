import { Chip } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import './RoleCell.scss'

export default function RoleCell(): ReactElement {
  return (
    <div className="role-cell">
      <Chip className="member-chip" label={terms.Group.member} />
    </div>

  )
}
