/* eslint-disable react/display-name */
import { IconButton } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CustomCopyCell from 'components/CustomCopyCell/CustomCopyCell'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { Column } from 'components/CustomTable/types'
import { ReactElement } from 'react'
import { User } from 'services/cerbereTypes'
import UserServices from 'services/UserServices'
import { store } from 'Store'

import { isRoot } from 'helpers/permissions'
import { setUserGroup } from 'reducers/user'

const enableUser = (newVal: boolean, id: string) => {
  if (newVal) {
    store.dispatch(UserServices.enableUser(id))
  } else {
    store.dispatch(UserServices.disableUser(id))
  }
}
const columnsHeader: Column[] = [
  {
    propertyName: 'username',
    align: 'left',
    width: '130px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
    sortable: true,
  },
  {
    propertyName: 'email',
    align: 'left',
    width: '250px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
    sortable: true,
  },
  {
    propertyName: 'firstName',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
    sortable: true,
  },
  {
    propertyName: 'lastName',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
    sortable: true,
  },
  {
    propertyName: 'id',
    align: 'left',
    width: '175px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
    sortable: true,
  },
  {
    propertyName: 'enabled',
    align: 'left',
    width: '95px',
    copy: false,
    formatter: (o: unknown, user: unknown): ReactElement => (
      <CustomSwitch
        onChange={newVal => enableUser(newVal, (user as User).id)}
        checked={o as boolean}
      />
    ),
    show: () => isRoot(),
  },
  {
    propertyName: 'action',
    align: 'left',
    width: '95px',
    copy: false,
    formatter: (_o, user: unknown): ReactElement => (
      <IconButton onClick={() => store.dispatch(setUserGroup((user as User).id))} aria-label="delete">
        <MoreHorizIcon />
      </IconButton>
    ),
    show: () => true,
  },

]

export default columnsHeader
