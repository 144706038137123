import terms from 'common/terms'
import { ReactElement } from 'react'
import { RequestState } from 'services/cerbereTypes'
import './Confirmation.scss'

type Props = {
  rejects: RequestState[];
  approvals: RequestState[];
}
export default function Confirmation({ rejects, approvals }: Props): ReactElement {
  return (
    <div className="confirmation-wrapper">
      {!!approvals.length && (
      <div className="message">
        <div className="title">{terms.Group.approvedUsers(approvals.length)}</div>

        <div className="name">
          {approvals.map(approval => (
            <span key={approval.userId}>
              {approval.user_display_name}
            </span>
          ))}
        </div>
      </div>
      )}

      {!!rejects.length && (
      <div className="message">
        <div className="title">{terms.Group.rejectedUsers(rejects.length)}</div>

        <div className="name">
          {rejects.map(reject => (
            <span key={reject.userId}>
              {reject.user_display_name}
            </span>
          ))}
        </div>
      </div>
      )}

    </div>
  )
}
