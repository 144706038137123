import terms from 'common/terms'
import CustomTable from 'components/CustomTable/CustomTable'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { ReactElement } from 'react'
import { RemoveResponse, RequestResponse } from 'services/cerbereTypes'
import './RequestSummary.scss'
import { SUMMARY_COLUMNS } from './const'

type Props = {
  open: boolean;
  toggle: () => void;
  requests: RequestResponse[] | RemoveResponse[];
}

export default function RequestSummary({
  open, toggle, requests,
}: Props): ReactElement {
  return (
    <ModalWrapper
      className="request-modal"
      toggle={toggle}
      open={open}
      title={terms.Group.requestSummary}
    >
      <CustomTable
        values={requests}
        loader={false}
        count={requests.length}
        pagination={false}
        columns={SUMMARY_COLUMNS}
        changePage={() => undefined}
      />

    </ModalWrapper>
  )
}
