/* eslint-disable no-restricted-syntax */

import { ExpandMore } from '@mui/icons-material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Collapse, IconButton, List, ListItem,
} from '@mui/material'
import { RootState } from 'Store'
import {
  Fragment, ReactElement,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenedAccordions } from 'reducers/group'
import { Service } from 'services/cerbereTypes'
import './GroupList.scss'
import Item from './Item'

type Props = {
  services: Service[]
}
export default function GroupList({ services }: Props): ReactElement {
  const dispatch = useDispatch()
  const { selectedGroup, openedAccordions } = useSelector((state: RootState) => state.groups)

  const handleToggle = (index: number) => {
    const currentIndex = openedAccordions.indexOf(index)
    const newOpen = [...openedAccordions]
    if (currentIndex === -1) {
      newOpen.push(index)
    } else {
      newOpen.splice(currentIndex, 1)
    }

    dispatch(setOpenedAccordions(newOpen))
  }

  return (
    <div
      className="group-container"
    >
      <List>
        {services?.map((service: Service, index: number) => (
          <Fragment key={service.project_name}>
            <ListItem
              className="service-title"
              disablePadding
            >
              <div className="group-title-text">
                <span className="service">{service.project_name}</span>
              </div>

              <IconButton className="expand-icon" onClick={() => handleToggle(index)}>
                {openedAccordions.indexOf(index) !== -1 ? <ExpandMore /> : <KeyboardArrowRightIcon /> }
              </IconButton>
            </ListItem>
            <Collapse
              in={openedAccordions.indexOf(index) !== -1}
              timeout="auto"
              unmountOnExit
              style={{ width: selectedGroup.id ? 'initial' : 'fit-content' }}
            >
              {service.groups.map(group => (
                <Item
                  service={service.project_name}
                  key={group.id}
                  groups={group}
                  index={2}
                />
              ))}
            </Collapse>
          </Fragment>
        ))}

      </List>
    </div>
  )
}
