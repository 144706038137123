/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
import CustomCopyCell from 'components/CustomCopyCell/CustomCopyCell'
import { Column } from 'components/CustomTable/types'
import GroupAction from 'components/GroupAction/GroupAction'
import MailCopyButton from 'components/MailCopyButton/MailCopyButton'
import RoleCell from 'components/RoleCell/RoleCell'
import { ReactElement } from 'react'
import { User } from 'services/cerbereTypes'

const columnsHeader: Column[] = [
  {
    propertyName: 'username',
    align: 'left',
    width: '130px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
  },
  {
    propertyName: 'email',
    align: 'left',
    width: '250px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
    additionalHeader: <MailCopyButton />,
  },
  {
    propertyName: 'firstName',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
  },
  {
    propertyName: 'lastName',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
  },
  {
    propertyName: 'can_delegate',
    align: 'left',
    width: '250px',
    copy: false,
    formatter: (_o): ReactElement => (<RoleCell />),
    show: () => true,
  },
  {
    propertyName: 'action',
    align: 'left',
    width: '85px',
    copy: false,
    formatter: (_o, user: unknown): ReactElement => (<GroupAction userId={(user as User).id} />),
    show: () => true,
  },
]

export default columnsHeader
