import AddBoxIcon from '@mui/icons-material/AddBox'
import { Badge, Button } from '@mui/material'
import { RootState, store } from 'Store'
import terms from 'common/terms'
import CustomInput from 'components/CustomInput/CustomInput'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import UserLevelSelect from 'components/UserLevelSelect/UserLevelSelect'
import { useDispatch, useSelector } from 'react-redux'
import { GroupState } from 'reducers/group'
import './GroupInfo.scss'
import GroupServices from 'services/GroupServices'
import { GroupUpdate } from 'services/cerbereTypes'

type Props = {
  open: boolean;
  toggle: () => void;
  togglePermissions: () => void
  toggleAuthorization: () => void
}

const DESCRIPTION_LENGTH = 300

export default function GroupInfo({
  open, toggle, togglePermissions, toggleAuthorization,
}: Props) {
  const dispatch = useDispatch()
  const { details, selectedGroup } = useSelector((state: RootState) => state.groups as GroupState)
  const { account } = useSelector((state: RootState) => state.user)

  const isLevel2 = () => details.level2_users.some(user => user.id === account.id)

  const updateGroup = (key: keyof GroupUpdate, value: string) => {
    const newDetails = {
      description: details.description,
      display_name: details.display_name,
      [key]: value,
      id: selectedGroup.id,
    }

    if (newDetails.description === details.description && newDetails.display_name === details.display_name) return

    store.dispatch(GroupServices.modifyInfo({
      description: details.description,
      display_name: details.display_name,
      [key]: value,
      id: selectedGroup.id,
    })).then(res => {
      if (res.type.includes('fulfilled')) {
        dispatch(GroupServices.getGroups(''))
      }
    })
  }

  return (
    <ModalWrapper
      toggle={toggle}
      open={open}
      title={(
        <>
          {terms.Group.parameters}
          <span className="group-title">{details.display_name}</span>
        </>
      )}
      footer={(
        <div className="custom-footer">
          <Badge badgeContent={details.warning || 0}>
            <Button onClick={togglePermissions} className="underline-button permission">{terms.Group.showPerm}</Button>
          </Badge>
        </div>
)}
    >
      <div className="inputs">
        <CustomInput
          label={terms.Group.name}
          value={details.display_name}
          onBlur={val => updateGroup('display_name', val)}
          disabled={!isLevel2()}
        />

        <CustomInput
          baseProps={{
            multiline: true, fullWidth: true, rows: 5,
          }}
          inputProps={{ maxLength: DESCRIPTION_LENGTH }}
          label={`${terms.Group.description} ${terms.Common.charLimit(DESCRIPTION_LENGTH)}`}
          value={details.description}
          onBlur={val => updateGroup('description', val)}
          disabled={!isLevel2()}
        />
      </div>
      <UserLevelSelect groupId={selectedGroup.id} disabled={!isLevel2()} groupInfo={details} />

      {isLevel2() && (
      <div className="add-admin">
        <Button
          className="underline-button"
          startIcon={<AddBoxIcon />}
          onClick={toggleAuthorization}
        >
          {terms.Group.addAdmin}
        </Button>
      </div>
      )}
    </ModalWrapper>
  )
}
