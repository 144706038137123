import {
  Button, FormControlLabel, FormGroup,
  Switch,
} from '@mui/material'
import { RootState, store } from 'Store'
import terms from 'common/terms'
import CustomAutocomplete from 'components/GroupDetails/CustomAutocomplete'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import UserChip from 'components/UserChip/UserChip'
import { usernameRule } from 'components/UserCreation/UserCreation'
import UsernamesInput from 'components/UsernamesInput/UsernamesInput'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GroupServices from 'services/GroupServices'
import UserServices from 'services/UserServices'
import {
  Levels, RemoveResponse, SimpleUser, User,
} from 'services/cerbereTypes'
import { ResponseError } from 'types'
import AdminMode from './AdminMode'
import './AuthorizationPopup.scss'

type Props = {
  open: boolean;
  toggle: () => void;
  title: string;
}

export default function AuthorizationPopup({
  open, toggle, title,
}: Props): ReactElement {
  const { selectedGroup, groupSearch, memberSearch } = useSelector((state: RootState) => state.groups)
  const dispatch = useDispatch()
  const [addedUsers, setUsers] = useState<User[]>([])
  const [addedUsernames, setUsernames] = useState<string>('')
  const formattedUsernames = addedUsernames ? addedUsernames.trim().split(usernameRule) : []
  const [level, setLevel] = useState(Levels.level1)
  const [searchMode, setSearch] = useState(true)

  const isAddingAdmin = () => title === terms.Group.addAdmin

  useEffect(() => {
    if (!open) {
      setUsers([])
      setUsernames('')
      setSearch(true)
      setLevel(Levels.level1)
    }
  }, [open])

  const removeUser = (user: User) => {
    const newUsers = addedUsers.filter(u => u.id !== user.id)
    setUsers(newUsers)
  }

  const addUser = (user: User) => {
    if (!addedUsers.find(u => u.id === user.id)) {
      setUsers(prev => [...prev, user])
    }
  }

  const addMembers = () => {
    store.dispatch(GroupServices.addUsersToGroup({
      groupId: selectedGroup.id,
      users: searchMode ? addedUsers : formattedUsernames,
      withCp: !searchMode,
    })).then(res => {
      const errorPayload = res?.payload as ResponseError
      if (res.type.includes('fulfilled')
      || (errorPayload.code === 400 && (errorPayload.data as RemoveResponse).successes.length)) {
        dispatch(GroupServices.getGroups(groupSearch))
        dispatch(GroupServices.getGroupMembers({ page: 1, groupId: selectedGroup.id, search: memberSearch }))
      }
    })
  }

  // todo refacto dupe
  const addUsers = () => {
    if (isAddingAdmin()) {
      const users: SimpleUser[] = []
      if (searchMode) {
        users.push(...addedUsers.map(u => ({
          ...u,
          display_name: `${u.firstName as string} ${u.lastName}` as string,
        })))

        users.forEach(user => {
          dispatch(GroupServices.assignToGroupWithLevel({
            groupId: selectedGroup.id, user, level, multipleAssign: true,
          }))
        })
      } else {
        store.dispatch(UserServices.getUsernamesInfo(formattedUsernames)).then(res => {
          users.push(...formattedUsernames.flatMap(u => {
            const userInfo = (res.payload as User[] || []).find((user: User) => user.username === u)
            if (userInfo) {
              return { ...userInfo, display_name: userInfo.displayName as string }
            }
            return []
          }))
          users.forEach(user => {
            dispatch(GroupServices.assignToGroupWithLevel({
              groupId: selectedGroup.id, user, level, multipleAssign: true,
            }))
          })
        })
      }
    } else {
      addMembers()
    }
  }

  const changeMode = () => {
    setSearch(prev => !prev)
    setUsers([])
    setLevel(Levels.level1)
    setUsernames('')
  }

  return (
    <ModalWrapper
      className="authorization-popup"
      toggle={toggle}
      open={open}
      title={title}
      footerButton={false}
      footer={(
        <Button
          disabled={!addedUsers.length && !formattedUsernames.length && !addedUsernames.length}
          className="button"
          variant="contained"
          onClick={addUsers}
        >
          {isAddingAdmin() ? terms.Group.assignAdmin(addedUsers.length || formattedUsernames.length,
            level === Levels.level1 ? 1 : 2)
            : terms.Group.assignMember(addedUsers.length || formattedUsernames.length)}
        </Button>
      )}

    >

      <div className="w-100">
        {searchMode ? (
          <CustomAutocomplete onSelect={val => {
            addUser(val)
          }}
          />
        ) : (

          <UsernamesInput
            value={addedUsernames}
            onChange={newVal => setUsernames(newVal)}
          />
        )}
      </div>

      <FormGroup className="switch-mode">
        <FormControlLabel
          control={<Switch checked={searchMode} onChange={changeMode} />}
          label={terms.Group.searchUser}
        />
      </FormGroup>

      {searchMode && (
      <div className="list">
        {(addedUsers as User[]).map(user => <UserChip key={user.id} user={user} onDelete={removeUser} />)}
      </div>
      )}

      {isAddingAdmin()
       && (!!addedUsers.length
       || !!addedUsernames.length) ? <AdminMode level={level} onChange={val => setLevel(val)} /> : <></>}

    </ModalWrapper>
  )
}
