import {
  Checkbox, ListItem, ListItemIcon, ListItemText,
} from '@mui/material'
import { ChangeEvent, ReactElement } from 'react'
import { Group } from 'services/cerbereTypes'

type Props = {
  groups: Group;
  index: number;
  checked: (id: string) => boolean;
  service: string;
  handleCheck: (event: ChangeEvent<HTMLInputElement>, value: string[], service: string) => void;
}
export default function Item({
  groups, index, checked, handleCheck, service,
}: Props): ReactElement {
  return (
    <>
      <ListItem className="item" sx={{ pl: index * 4 }}>
        <ListItemIcon className="checkbox">
          <Checkbox
            onChange={evt => handleCheck(evt, [groups.id], service)}
            checked={checked(groups.id)}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText className="list-text" primary={groups.display_name} />

      </ListItem>
      {groups?.children?.map(child => (
        <Item
          key={child.id}
          handleCheck={handleCheck}
          service={service}
          checked={checked}
          groups={child}
          index={index + 1}
        />
      ))}
    </>
  )
}
