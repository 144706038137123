import { Input } from '@mui/material'
import { RootState, store } from 'Store'
import terms from 'common/terms'
import { debounce } from 'lodash'
import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { GroupState, updateApproval } from 'reducers/group'
import { RequestState, RequestStatus } from 'services/cerbereTypes'

type Props = {
  userId: string;
}

const update = debounce((reason: string, request: RequestState) => store.dispatch(updateApproval(
  { ...request, reason },
)), 300)

export default function RefusalInput({ userId }: Props): ReactElement {
  const { requestApprovals } = useSelector((state: RootState) => state.groups as GroupState)
  const request = requestApprovals.find(elem => elem.userId === userId)
  const [value, setValue] = useState(request?.reason)

  const updateReason = (reason: string) => {
    if (!request) return
    setValue(reason)

    update(reason, request)
  }

  return (
    request ? (
      <Input
        disableUnderline
        value={value}
        onChange={evt => updateReason(evt.target.value)}
        placeholder={request.state === RequestStatus.reject ? terms.Group.commentReason : terms.Group.comment}
      />
    ) : <></>)
}
