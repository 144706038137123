import { Column } from 'components/CustomTable/types'
import { RequestResponse } from 'services/cerbereTypes'
import RequestStatusChip from './RequestStatusChip/RequestStatusChip'

// eslint-disable-next-line import/prefer-default-export
export const SUMMARY_COLUMNS: Column[] = [
  {
    propertyName: 'user',
    align: 'left',
    width: '100px',
    copy: false,
    formatter: (_: unknown, request: unknown) => (request as RequestResponse).username,
    show: () => true,
  },
  {
    propertyName: 'status',
    align: 'left',
    width: '40px',
    copy: false,
    formatter: (_: unknown, r: unknown) => <RequestStatusChip value={(r as RequestResponse).status} />,
    show: () => true,
  },
  {
    propertyName: 'information',
    align: 'left',
    width: '130px',
    copy: false,
    formatter: (_: unknown, request: unknown) => (
      <span className="information">{(request as RequestResponse).information}</span>
    ),
    show: () => true,
  }]
