import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@mui/material'
import terms from 'common/terms'
import { MouseEvent, ReactElement, useState } from 'react'
import './GroupAction.scss'
import { useDispatch, useSelector } from 'react-redux'
import GroupServices from 'services/GroupServices'
import { RootState, store } from 'Store'

type Props = {
  userId: string;
}
export default function GroupAction({ userId }: Props): ReactElement {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { selectedGroup, groupSearch } = useSelector((state: RootState) => state.groups)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    store.dispatch(GroupServices.removeUsersFromGroup({ groupId: selectedGroup.id, userIds: [userId] })).then(res => {
      if (res.type.includes('/fulfilled')) {
        dispatch(GroupServices.getGroups(groupSearch))
      }
    })
    handleClose()
  }

  return (
    <>
      <IconButton onClick={handleClick}><MoreHorizIcon /></IconButton>

      <Menu
        className="manage-users-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>{terms.Group.removeAuthorization}</MenuItem>
      </Menu>
    </>
  )
}
