import { InputBase } from '@mui/material'
import { ReactElement } from 'react'
import './UsernamesInput.scss'
import terms from 'common/terms'

type Props = {
  value: string;
  onChange: (arg: string) => void
}

export default function UsernamesInput({ value, onChange }: Props): ReactElement {
  return (
    <>
      <div className="usernames-label">
        {`${terms.User.writeUsernames}. ${terms.Group.importInfo}`}
      </div>
      <InputBase
        className="usernames-input"
        multiline
        fullWidth
        rows={6}
        onChange={event => onChange(event.target.value)}
        value={value}
      />
    </>
  )
}
