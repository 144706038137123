import { Button } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import CustomTable from 'components/CustomTable/CustomTable'
import DisabledToolTip from 'components/DisabledTooltip/DisabledTooltip'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GroupState, resetApproval } from 'reducers/group'
import { GroupRequest, RequestStatus } from 'services/cerbereTypes'
import Buttons from './Confirmation/Buttons'
import Confirmation from './Confirmation/Confirmation'
import './MembershipPopup.scss'
import { REQUEST_COLUMNS } from './const'

type Props = {
  open: boolean;
  toggle: () => void;
  requests: GroupRequest[];
}

export default function MembershipPopup({
  open, toggle, requests,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { requestApprovals, selectedGroup } = useSelector((state: RootState) => state.groups as GroupState)
  const rejectRequests = requestApprovals.filter(request => request.state === RequestStatus.reject)
  const approveRequests = requestApprovals.filter(request => request.state === RequestStatus.approve)
  const [showConfirmation, setConfirmation] = useState(false)

  const getButtonText = () => {
    if (approveRequests.length && rejectRequests.length) {
      return terms.Group.approveRejectRequest(approveRequests.length, rejectRequests.length)
    }

    if (approveRequests.length) {
      return terms.Group.approveRequest(approveRequests.length)
    }

    if (rejectRequests.length) {
      return terms.Group.rejectRequest(rejectRequests.length)
    }

    return terms.Common.confirm
  }

  const toggleModal = () => {
    if (open) {
      setConfirmation(false)
      dispatch(resetApproval())
    }
    toggle()
  }

  const missingApproval = () => (rejectRequests.length + approveRequests.length !== requests.length)
  const missingRejectInfo = () => rejectRequests.some(elem => !elem.reason)

  useEffect(() => () => {
    if (!open) {
      setConfirmation(false)
    }
  }, [open])

  return (
    <ModalWrapper
      className="membership-modal"
      toggle={toggleModal}
      open={open}
      title={showConfirmation ? terms.Common.confirmation : terms.Group.pendingRequest(requests
        .filter(grp => grp.group_id === selectedGroup.id).length)}
      footerButton={false}
      footer={showConfirmation ? (
        <Buttons toggle={toggleModal} />
      ) : (
        <div className="custom-footer">
          <DisabledToolTip
            title={missingApproval() ? terms.Group.missingApproval : terms.Group.missingRejectInfo}
            disabled={!missingApproval() && !missingRejectInfo()}
          >
            <Button
              disabled={missingApproval() || missingRejectInfo()}
              className="confirm-btn"
              variant="contained"
              onClick={() => setConfirmation(true)}
            >
              {getButtonText()}
            </Button>
          </DisabledToolTip>
        </div>
      )}
    >

      {open && showConfirmation ? (
        <Confirmation
          rejects={rejectRequests}
          approvals={approveRequests}
        />
      ) : (
        <CustomTable
          values={requests}
          loader={false}
          count={requests.length}
          pagination={false}
          columns={REQUEST_COLUMNS}
          changePage={() => undefined}
        />
      )}

    </ModalWrapper>
  )
}
