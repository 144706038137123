import groupIcon from 'assets/icons/group.svg'
import infoIcon from 'assets/icons/info.svg'
import tokenIcon from 'assets/icons/token.svg'
import userIcon from 'assets/icons/user.svg'
import { NavElement } from './TabElement'

export const editorElements: NavElement[] = [
  {
    icon: userIcon,
    title: 'Utilisateurs',
    path: 'users',
  },
  {
    icon: groupIcon,
    title: 'Mes Applications',
    path: 'apps',
  },
  {
    icon: tokenIcon,
    title: 'Tokens',
    path: 'tokens',
  },
  {
    icon: infoIcon,
    title: 'Aide à l\'utilisation',
    path: 'help',
  },
]
export const userElements: NavElement[] = [
  {
    icon: tokenIcon,
    title: 'Tokens',
    path: 'tokens',
  },
  {
    icon: infoIcon,
    title: 'Aide à l\'utilisation',
    path: 'help',
  },
]
