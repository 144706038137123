/* eslint-disable no-nested-ternary */
import { Paper } from '@mui/material'
import terms from 'common/terms'
import FeedbackSnackBar from 'components/FeedbackSnackBar/FeedbackSnackBar'
import GroupBar from 'components/GroupBar/GroupBar'
import GroupDetails from 'components/GroupDetails/GroupDetails'
import GroupList from 'components/GroupList/GroupList'
import InfoPanel, { Severity } from 'components/InfoPanel/InfoPanel'
import Loader from 'components/Loader'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetGroupSearch, resetGroups, setOpenedAccordions } from 'reducers/group'
import GroupServices from 'services/GroupServices'
import { RootState } from 'Store'
import './Groups.scss'

export default function Groups(): ReactElement {
  const dispatch = useDispatch()
  const {
    services, groupSearch, groupsLoading, selectedGroup,
  } = useSelector((state: RootState) => state.groups)

  useEffect(() => {
    dispatch(GroupServices.getMembershipRequests())
  }, [])
  useEffect(() => {
    dispatch(GroupServices.getGroups(groupSearch))
  }, [groupSearch])

  useEffect(() => () => {
    dispatch(resetGroupSearch())
    dispatch(resetGroups())
    dispatch(setOpenedAccordions([]))
  }, [])

  return (
    <Paper className="group-main-container" elevation={0} square>

      <div className="group-tree">
        <GroupBar length={services.count} />
        {groupsLoading ? (

          <div
            className="loading"
          >
            <Loader />
          </div>
        ) : (
          services?.results.length > 0 ? <GroupList services={services.results} /> : (
            <InfoPanel
              title={terms.Group.notFound}
              severity={Severity.warning}
            />
          ))}
      </div>
      {selectedGroup?.id !== '' && (
        <div className="group-details">

          <GroupDetails />

        </div>
      )}
      <FeedbackSnackBar />
    </Paper>
  )
}
