import {
  Checkbox, FormControlLabel, FormGroup, Link,
} from '@mui/material'
import { Column } from 'components/CustomTable/types'
import { ReactElement } from 'react'
import { setTokenPermissions } from 'reducers/token'
import { store } from 'Store'

const columnsHeader: Column[] = [
  {
    propertyName: 'role_name',
    align: 'left',
    width: '200px',
    copy: false,
    formatter: (o: unknown) => (
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={store.getState().tokens.askPermissions.includes(o as string)}
              onChange={() => store.dispatch(setTokenPermissions(o as string))}
            />
)}
          label={o as string}
        />
      </FormGroup>
    ),
    show: () => true,
  },
  {
    propertyName: 'doc_link',
    align: 'left',
    width: '230px',
    copy: false,
    formatter: (o: unknown): ReactElement => (
      <Link
        target="_blank"
        href={o as string}
      >
        {o as string}
      </Link>
    ),
    show: () => true,
  },
]

export default columnsHeader
