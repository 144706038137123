import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton, Modal, Paper,
} from '@mui/material'
import terms from 'common/terms'
import { PropsWithChildren, ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import './ModalWrapper.scss'

type Props = {
  open: boolean;
  title: ReactNode;
  toggle: () => void;
  footer?: ReactNode;
  className?: string;
  footerButton?: boolean;
}

const defaultProps = {
  footer: <></>,
  footerButton: true,
  className: '',
}

export default function ModalWrapper({
  open, toggle, children, footer, title, className, footerButton,
}: PropsWithChildren<Props>) {
  return (
    <Modal
      className={`modal-wrapper ${className}`}
      open={open}
      onClose={toggle}
    >
      <Paper
        className="main"
        elevation={0}
        square
      >
        <div className="header">
          <div className="title">{title}</div>

          <IconButton onClick={toggle} className="close-button">
            <CloseIcon />
          </IconButton>

        </div>
        <div className="body">
          {children}
        </div>
        {footerButton && (
        <div className="d-flex w-100 row align-items-end justify-content-center footer">

          <Button
            className="button"
            variant="contained"
            onClick={toggle}
          >
            {terms.Common.ok}
          </Button>

        </div>
        )}
        {footer}
      </Paper>
    </Modal>
  )
}

ModalWrapper.defaultProps = defaultProps
