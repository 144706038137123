/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Order } from 'objects/types'
import {
  AssignResponse, User, UserCreationResponse, UserResponse,
} from 'services/cerbereTypes'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const formatSorter = (columnField: string, direction: Order) => {
  if (direction === 'asc') { return columnField }
  if (direction === 'desc') { return `-${columnField}` }

  return null
}

const getUsers = createAsyncThunk<UserResponse, any, ThunkApiConfig>(
  'users/getUsers',
  async ({ search, sortItem, page }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/v2/users/`, {
        page,
        ordering: sortItem ? formatSorter(sortItem.field, sortItem.sort) : undefined,
        search: search || undefined,
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUser = createAsyncThunk<User, any, ThunkApiConfig>(
  'users/getUser',
  async ({ id, search }, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/users/${id}`, { search: search || undefined })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const enableUser = createAsyncThunk<any, string, ThunkApiConfig>(
  'users/enableUser',
  async (id, thunkApi) => {
    try {
      await post(`/${URI.cerbereApi}/v2/users/${id}/enable`, {})
      return { id }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const disableUser = createAsyncThunk<any, string, ThunkApiConfig>(
  'users/disableUser',
  async (id, thunkApi) => {
    try {
      await post(`/${URI.cerbereApi}/v2/users/${id}/disable`, {})
      return { id }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const assignToGroups = createAsyncThunk<AssignResponse, any, ThunkApiConfig>(
  'users/assignToGroups',
  async ({ groups, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/users/${userId}/assign_groups`,
        groups)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const removeFromGroups = createAsyncThunk<AssignResponse, any, ThunkApiConfig>(
  'users/removeFromGroups',
  async ({ groups, userId }, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/users/${userId}/remove_groups`, groups)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const createUser = createAsyncThunk<UserCreationResponse, any, ThunkApiConfig>(
  'users/createUser',
  async (object, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/users/`, object)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUsernamesInfo = createAsyncThunk<User[], string[], ThunkApiConfig>(
  'users/getUsernamesInfo',
  async (usernames, thunkApi) => {
    try {
      const response = await post(`/${URI.cerbereApi}/v2/users/dump`, usernames)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const UserServices = {
  getUsers,
  getUser,
  enableUser,
  disableUser,
  assignToGroups,
  removeFromGroups,
  createUser,
  getUsernamesInfo,
}

export default UserServices
